@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('https://res.cloudinary.com/startar/raw/upload/v1616733119/Startar/fonts/support/GTWalsheimPro-Regular_d8cbvi.eot');
    src: local('GT Walsheim Pro Regular'), local('GTWalsheimPro-Regular'),
        url('https://res.cloudinary.com/startar/raw/upload/v1616733119/Startar/fonts/support/GTWalsheimPro-Regular_d8cbvi.eot?#iefix') format('embedded-opentype'),
        url('https://res.cloudinary.com/startar/raw/upload/v1616733119/Startar/fonts/support/GTWalsheimPro-Regular_wyvrxw.woff2') format('woff2'),
        url('https://res.cloudinary.com/startar/raw/upload/v1616733119/Startar/fonts/support/GTWalsheimPro-Regular_kkwifo.woff') format('woff'),
        url('https://res.cloudinary.com/startar/raw/upload/v1616733119/Startar/fonts/support/GTWalsheimPro-Regular_dj29bv.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('https://res.cloudinary.com/startar/raw/upload/v1616733118/Startar/fonts/support/GTWalsheimPro-Bold_rexkxx.eot');
    src: local('GT Walsheim Pro Bold'), local('GTWalsheimPro-Bold'),
        url('https://res.cloudinary.com/startar/raw/upload/v1616733118/Startar/fonts/support/GTWalsheimPro-Bold_rexkxx.eot?#iefix') format('embedded-opentype'),
        url('https://res.cloudinary.com/startar/raw/upload/v1616733118/Startar/fonts/support/GTWalsheimPro-Bold_zqsn1m.woff2') format('woff2'),
        url('https://res.cloudinary.com/startar/raw/upload/v1616733118/Startar/fonts/support/GTWalsheimPro-Bold_vdiroa.woff') format('woff'),
        url('https://res.cloudinary.com/startar/raw/upload/v1616733118/Startar/fonts/support/GTWalsheimPro-Bold_qqwwty.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('https://res.cloudinary.com/startar/raw/upload/v1616733118/Startar/fonts/support/GTWalsheimPro-Medium_hd9tc4.eot');
    src: local('GT Walsheim Pro Medium'), local('GTWalsheimPro-Medium'),
        url('https://res.cloudinary.com/startar/raw/upload/v1616733118/Startar/fonts/support/GTWalsheimPro-Medium_hd9tc4.eot?#iefix') format('embedded-opentype'),
        url('https://res.cloudinary.com/startar/raw/upload/v1616733118/Startar/fonts/support/GTWalsheimPro-Medium_jrumck.woff2') format('woff2'),
        url('https://res.cloudinary.com/startar/raw/upload/v1616733118/Startar/fonts/support/GTWalsheimPro-Medium_nejjda.woff') format('woff'),
        url('https://res.cloudinary.com/startar/raw/upload/v1616733118/Startar/fonts/support/GTWalsheimPro-Medium_iwkrhn.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}