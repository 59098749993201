@font-face {
  font-family: 'Recoleta Alt';
  src: url('https://res.cloudinary.com/startar/raw/upload/v1616733292/Startar/fonts/header/RecoletaAlt-SemiBold_eomu6v.eot');
  src: local('Recoleta Alt SemiBold'), local('RecoletaAlt-SemiBold'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733292/Startar/fonts/header/RecoletaAlt-SemiBold_eomu6v.eot?#iefix') format('embedded-opentype'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733292/Startar/fonts/header/RecoletaAlt-SemiBold_d1vkiq.woff2') format('woff2'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733292/Startar/fonts/header/RecoletaAlt-SemiBold_mq9nik.woff') format('woff'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733292/Startar/fonts/header/RecoletaAlt-SemiBold_upnqji.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta Alt';
  src: url('https://res.cloudinary.com/startar/raw/upload/v1616733291/Startar/fonts/header/RecoletaAlt-Medium_r0b1zb.eot');
  src: local('Recoleta Alt Medium'), local('RecoletaAlt-Medium'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733291/Startar/fonts/header/RecoletaAlt-Medium_r0b1zb.eot?#iefix') format('embedded-opentype'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733292/Startar/fonts/header/RecoletaAlt-Medium_obs2ky.woff2') format('woff2'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733292/Startar/fonts/header/RecoletaAlt-Medium_p3ewmf.woff') format('woff'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733292/Startar/fonts/header/RecoletaAlt-Medium_l7qdoc.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta Alt';
  src: url('https://res.cloudinary.com/startar/raw/upload/v1616733291/Startar/fonts/header/RecoletaAlt-Bold_rqdi9t.eot');
  src: local('Recoleta Alt Bold'), local('RecoletaAlt-Bold'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733291/Startar/fonts/header/RecoletaAlt-Bold_rqdi9t.eot?#iefix') format('embedded-opentype'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733291/Startar/fonts/header/RecoletaAlt-Bold_wbqs5m.woff2') format('woff2'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733291/Startar/fonts/header/RecoletaAlt-Bold_ozkmx6.woff') format('woff'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733291/Startar/fonts/header/RecoletaAlt-Bold_gn2hnd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Recoleta Alt';
  src: url('https://res.cloudinary.com/startar/raw/upload/v1616733291/Startar/fonts/header/RecoletaAlt-Black_yz8otw.eot');
  src: local('Recoleta Alt Black'), local('RecoletaAlt-Black'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733291/Startar/fonts/header/RecoletaAlt-Black_yz8otw.eot?#iefix') format('embedded-opentype'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733291/Startar/fonts/header/RecoletaAlt-Black_uo1scq.woff2') format('woff2'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733291/Startar/fonts/header/RecoletaAlt-Black_ymie7u.woff') format('woff'),
    url('https://res.cloudinary.com/startar/raw/upload/v1616733291/Startar/fonts/header/RecoletaAlt-Black_imtutd.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
